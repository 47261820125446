import { useState, useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Info from "@material-ui/icons/Info";
import Person from "@material-ui/icons/Person";
import Group from "@material-ui/icons/Group";
import FlightLand from "@material-ui/icons/FlightLand";
import FlightTakeOff from "@material-ui/icons/FlightTakeoff";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Phone from "@material-ui/icons/PhoneInTalk";
import Place from "@material-ui/icons/Place";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Today from "@material-ui/icons/Restore";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useAuth } from "../context/AuthContext";
import { useSnackbar } from "notistack";
import moment from "moment";
import "moment/locale/es";

// utils
import fetcher from "../utils/fetcher";

// components
import Navigation from "./Layout/Navigation";
import Loader from "./Loader";

type UnlockType = {
    className?: string;
    disabled: boolean;
    label: string;
    propertyId: number;
    type: number;
    confirmMessage: string;
};

const UnlockButton = ({ className, disabled, label, propertyId, type, confirmMessage }: UnlockType) => {
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const handleUnlock = async () => {
        setConfirm(false);
        setLoading(true);
        try {
            await fetcher(`oa/smartlock/${propertyId}`, {
                method: "POST",
                body: JSON.stringify({ doortype: type })
            });

            enqueueSnackbar(t("list.lockOpened"), {
                variant: "success",
                preventDuplicate: true
            });
        } catch (e) {
            enqueueSnackbar(t("list.lockError"), {
                variant: "error",
                preventDuplicate: true
            });
        }
        setLoading(false);
    };

    const onClick = () => {
        if (!confirm) {
            setConfirm(true);
        } else {
            handleUnlock();
        }
    };

    useEffect(() => {
        const timer = window.setInterval(() => {
            setConfirm(false);
        }, 3000);
        return () => {
            window.clearInterval(timer);
        };
    }, []);

    return (
        <Button
            variant="contained"
            color="primary"
            className={className}
            style={
                confirm
                    ? {
                          backgroundColor: "red"
                      }
                    : {}
            }
            disabled={disabled}
            onClick={onClick}
        >
            {loading ? <CircularProgress size={24} color="secondary" /> : confirm ? confirmMessage : label}
        </Button>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    content: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    datePicker: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
        width: 100
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    secondaryBar: {
        backgroundColor: "rgb(232, 232, 232)"
    },
    button: {
        marginTop: 10
    }
}));

type FilterType = {
    viewDate: string;
};

function OccupiedList() {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { auth } = useAuth();
    const { enqueueSnackbar } = useSnackbar();

    moment.locale(i18n.language);

    const [propertiesList, setPropertiesList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<FilterType>({
        viewDate: moment().format("YYYY-MM-DD")
    });

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            try {
                const properties = await fetcher(`oa/list`, {
                    method: "POST",
                    body: JSON.stringify(filters)
                });

                setPropertiesList(properties.data);
            } catch (e) {
                enqueueSnackbar(t("misc.error"), {
                    variant: "error",
                    preventDuplicate: true
                });
            }

            setLoading(false);
        }

        fetchData();
    }, [filters, enqueueSnackbar, t]);

    const goToToday = () => {
        setFilters(prev => ({
            ...prev,
            viewDate: moment().format("YYYY-MM-DD")
        }));
    };

    const handleDatePrev = () => {
        const past = moment(filters.viewDate).subtract(1, "days").format("YYYY-MM-DD");

        setFilters(prev => ({ ...prev, viewDate: past }));
    };

    const handleDateChange = (val: any) => {
        setFilters(prev => ({ ...prev, viewDate: moment(val).format("YYYY-MM-DD") }));
    };

    const handleDateNext = () => {
        const next = moment(filters.viewDate).add(1, "days").format("YYYY-MM-DD");

        setFilters(prev => ({ ...prev, viewDate: next }));
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Navigation
                buttons={
                    <IconButton color="inherit" onClick={goToToday} aria-label={t("misc.today")}>
                        <Today />
                    </IconButton>
                }
                toolbarSecondary={
                    <Toolbar disableGutters={true} className={classes.secondaryBar}>
                        <IconButton onClick={handleDatePrev} style={{ marginLeft: 5 }} aria-label={t("misc.previous")}>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <div className={classes.datePicker}>
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                                <DatePicker
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    fullWidth={true}
                                    format="DD/MM/YYYY"
                                    autoOk={true}
                                    value={filters.viewDate}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <IconButton onClick={handleDateNext} style={{ marginRight: 5 }} aria-label={t("misc.next")}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Toolbar>
                }
            />

            <main className={classes.content}>
                {propertiesList.map(property => {
                    let color = null;

                    if (moment(property.guest.arrivalDate).isSame(moment(), "day")) {
                        color = "green";
                    } else if (moment(property.guest.departureDate).isSame(moment(), "day")) {
                        color = "red";
                    }

                    return (
                        <Accordion key={property.id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography
                                    className={classes.heading}
                                    style={
                                        color
                                            ? {
                                                  color: color
                                              }
                                            : {}
                                    }
                                >
                                    <b>{property.propertyName}</b> {property.propertyHasSmartLock > 0 ? <FontAwesomeIcon icon="door-open" /> : null}{" "}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component="div">
                                    <div className={classes.grow}>
                                        {auth.accessLevel > 1 && (
                                            <>
                                                <Info style={{ fontSize: 15 }} /> <b>{t("list.ref")}:</b> {property.guest.refId}
                                                <br />
                                                <Person style={{ fontSize: 15 }} /> <b>{t("list.name")}:</b> {property.guest.guestName}
                                                <br />
                                                <Phone style={{ fontSize: 15 }} /> <b>{t("list.phone")}:</b> <a href={"tel:" + property.guest.guestPhone}>{property.guest.guestPhone}</a>
                                                <br />
                                            </>
                                        )}
                                        <Place style={{ fontSize: 15 }} /> <b>{t("list.address")}:</b>{" "}
                                        <a target="_blank" rel="noopener noreferrer" href={"http://maps.google.com/?daddr=" + encodeURI(property.propertyAddress)}>
                                            {property.propertyAddress}, {property.propertyFloor} - {property.propertyDoor}
                                        </a>
                                        <br />
                                        {auth.accessLevel > 1 && (
                                            <>
                                                <DateRangeIcon style={{ fontSize: 15 }} /> <b>{t("list.nights")}:</b> {property.guest.numNights}
                                                <br />
                                                <Group style={{ fontSize: 15 }} /> <b>{t("list.pax")}:</b> {property.guest.pax}
                                                <br />
                                                <FlightLand style={{ fontSize: 15 }} /> <b>{t("list.arrival")}:</b> {moment(property.guest.arrivalDate).format("DD/MM/YYYY HH:mm")}
                                                <br />
                                                <FlightTakeOff style={{ fontSize: 15 }} /> <b>{t("list.departure")}:</b> {moment(property.guest.departureDate).format("DD/MM/YYYY HH:mm")}
                                                <br />
                                                {auth.accessLevel > 2 && (
                                                    <>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            onClick={() => {
                                                                history.push(`/issue/${property.guest.refId}`);
                                                            }}
                                                        >
                                                            {t("list.newIssue")}
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            style={{ marginLeft: 5 }}
                                                            className={classes.button}
                                                            onClick={() => window.open(`${process.env.REACT_APP_API_URL}/../Bookings/edit?id=${property.guest.bookingId}`)}
                                                        >
                                                            {t("list.viewBooking")}
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            style={{ marginLeft: 5 }}
                                                            className={classes.button}
                                                            onClick={() => window.open(`${process.env.REACT_APP_API_URL}/../Chat/index?id=${property.guest.bookingId}`)}
                                                        >
                                                            {t("list.viewChat")}
                                                        </Button>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {property.propertyHasSmartLock > 0 && auth.accessLevel > 0 && (
                                            <div>
                                                <UnlockButton
                                                    className={classes.button}
                                                    disabled={property.propertyHasSmartLock === 1}
                                                    propertyId={property.propertyId}
                                                    type={2}
                                                    confirmMessage={t("list.pressAgain")}
                                                    label={t("list.frontDoor")}
                                                />
                                                <br />

                                                <UnlockButton
                                                    className={classes.button}
                                                    disabled={property.propertyHasSmartLock === 3}
                                                    propertyId={property.propertyId}
                                                    type={1}
                                                    confirmMessage={t("list.pressAgain")}
                                                    label={t("list.aptDoor")}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </main>
        </div>
    );
}

export default OccupiedList;
