import React from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";

//components
import Login from "./components/Login";
import Main from "./components/Main";
import Settings from "./components/Settings";
import PrivateRoute from "./components/PrivateRoute";
import AddIssue from "./components/AddIssue";

//context
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "./context/ConfigContext";

//config
import "./i18n/config";

//fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

function App() {
  return (
    <ConfigProvider>
      <CssBaseline />
      <SnackbarProvider>
        <AuthProvider>
          <Router>
            <Switch>
              <PrivateRoute exact path="/" component={Main} />
              <Route exact path="/issue/:id" component={AddIssue} />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/settings" component={Settings} />
              <Redirect to="/" />
            </Switch>
          </Router>
        </AuthProvider>
      </SnackbarProvider>
    </ConfigProvider>
  );
}

export default App;
