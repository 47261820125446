import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";

// components
import Logo from "./Logo";

//context
import { useAuth } from "../../context/AuthContext";
import { useConfig } from "../../context/ConfigContext";

const useStyles = makeStyles(() => ({
    title: {
        flexGrow: 1
    },
    headerIcon: {
        marginRight: 5
    },
    grow: {
        flexGrow: 1
    }
}));

type NavigationType = {
    buttons?: any;
    toolbarSecondary?: any;
};

function Navigation({ buttons, toolbarSecondary }: NavigationType) {
    const classes = useStyles();
    const { auth, logout } = useAuth();
    const history = useHistory();
    const { t } = useTranslation();

    const {
        config: { appName }
    } = useConfig();

    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = Boolean(anchorEl);

    const handleMenu = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const userLogout = async () => {
        await logout();

        history.replace("/login");
    };

    return (
        <AppBar position="sticky">
            <Toolbar>
                <Logo className={classes.headerIcon} />
                <Typography variant="h6" className={classes.title}>
                    {appName}
                </Typography>

                <div className={classes.grow} />

                {buttons}

                <IconButton aria-label={t("nav.userProfile")} aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                    <AccountCircle />
                </IconButton>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={openMenu}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            history.push("/settings");
                        }}
                    >
                        {t("nav.settings")}
                    </MenuItem>
                    <MenuItem onClick={userLogout}>{t("nav.logout", { user: auth.userName })}</MenuItem>
                </Menu>
            </Toolbar>
            {toolbarSecondary}
        </AppBar>
    );
}

export default Navigation;
