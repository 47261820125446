import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Add from "@material-ui/icons/Add";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

///utils
import fetcher from "../utils/fetcher";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingTop: theme.spacing()
    },
    flex: {
        flex: 1
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 300
    }
}));

type IssueType = {
    issueDesc: string;
    issuePriority: number;
};

function AddIssue() {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [newIssue, setNewIssue] = useState<IssueType>({
        issueDesc: "",
        issuePriority: 1
    });

    const handleChange = (event: any) => {
        setNewIssue(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    const addIssue = async () => {
        setIsDisabled(true);

        try {
            await fetcher("oa/add", {
                method: "POST",
                body: JSON.stringify({
                    ...newIssue,
                    refId: id
                })
            });

            history.push("/");
        } catch (e) {
            enqueueSnackbar(t("misc.error"), {
                variant: "error",
                preventDuplicate: true
            });
        }

        setIsDisabled(false);
    };

    const checkforErrors = useCallback(() => {
        let errorCount = 0;

        if (!newIssue.issueDesc) {
            errorCount++;
        }

        if (!newIssue.issuePriority) {
            errorCount++;
        }

        setIsDisabled(errorCount > 0 ? true : false);
    }, [newIssue]);

    useEffect(() => {
        checkforErrors();
    }, [newIssue, checkforErrors]);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            history.push("/");
                        }}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label={t("misc.back")}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        {t("add.title")}
                    </Typography>

                    <IconButton onClick={addIssue} disabled={isDisabled} color="inherit" aria-label={t("add.add")}>
                        <Add />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <div className={classes.content}>
                <FormControl className={classes.formControl}>
                    <TextField required label={t("add.issueDesc")} multiline onChange={handleChange} name="issueDesc" value={newIssue.issueDesc} error={!Boolean(newIssue.issueDesc)} />
                </FormControl>
                <br /> <br />
                <FormControl required className={classes.formControl}>
                    <InputLabel htmlFor="issuePriority">{t("add.issuePriority")}</InputLabel>
                    <Select
                        native
                        value={newIssue.issuePriority}
                        onChange={handleChange}
                        inputProps={{
                            name: "issuePriority",
                            id: "issuePriority"
                        }}
                    >
                        <option value={1}>{t("add.issuePriorityLow")}</option>
                        <option value={2}>{t("add.issuePriorityMedium")}</option>
                        <option value={3}>{t("add.issuePriorityHigh")}</option>
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

export default AddIssue;
