import { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles/createTheme";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

//context
import { useConfig } from "../context/ConfigContext";
import { useAuth } from "../context/AuthContext";

// components
import Logo from "./Layout/Logo";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    button: {
        margin: theme.spacing(1)
    },
    headerIcon: {
        marginRight: 5
    }
}));

type LoginType = {
    username: string;
    password: string;
};

function Login() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const {
        config: { appName }
    } = useConfig();

    const [user, setUser] = useState<LoginType>({ username: "", password: "" });
    const { isAuthenticated, login } = useAuth();

    if (isAuthenticated) {
        history.push("/");
    }

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();

        try {
            await login(user);
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: "error",
                preventDuplicate: true
            });
        }
    };

    const handleChange = (event: any) => {
        setUser(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Logo className={classes.headerIcon} />
                    <Typography variant="h5" color="inherit" className={classes.grow}>
                        {appName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <form noValidate autoComplete="off" onSubmit={event => handleFormSubmit(event)}>
                <TextField required id="username" name="username" label={t("login.username")} className={classes.textField} margin="normal" value={user.username} onChange={handleChange} />
                <br />

                <TextField
                    required
                    id="password"
                    name="password"
                    label={t("login.password")}
                    className={classes.textField}
                    type="password"
                    margin="normal"
                    value={user.password}
                    onChange={handleChange}
                />
                <br />
                <Button type="submit" variant="contained" color="primary" className={classes.button}>
                    {t("login.login")}
                </Button>
            </form>
        </div>
    );
}

export default Login;
