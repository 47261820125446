import { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import { Theme } from "@material-ui/core/styles/createTheme";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        paddingLeft: theme.spacing(),
        paddingTop: theme.spacing()
    },
    button: {
        margin: theme.spacing()
    }
}));

function Settings() {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const [currentLang, setCurrentLang] = useState<string>(i18n.language);

    const history = useHistory();

    function changeLang(lang: string) {
        i18n.changeLanguage(lang);
        setCurrentLang(lang);
        localStorage.setItem("lang", lang);
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        onClick={() => {
                            history.push("/");
                        }}
                        className={classes.menuButton}
                        color="inherit"
                        aria-label={t("misc.back")}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        {t("settings.title")}
                    </Typography>
                </Toolbar>
            </AppBar>

            <div className={classes.content}>
                <Button color={currentLang === "en" ? "primary" : "default"} onClick={() => changeLang("en")} variant="contained" className={classes.button}>
                    {t("languages.english")}
                </Button>
                <Button color={currentLang === "es" ? "primary" : "default"} onClick={() => changeLang("es")} variant="contained" className={classes.button}>
                    {t("languages.spanish")}
                </Button>
            </div>
        </div>
    );
}

export default Settings;
