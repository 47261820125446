import LocalHotel from "@material-ui/icons/LocalHotel";

const configuration = {
  appName: "occupiedApp",
  appIcon: <LocalHotel />,
  primary: "#448aff",
  secondary: "#03a9f4",
};

export default configuration;
