import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

interface PrivateRouteProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, exact, ...rest }: PrivateRouteProps) => {
    const { isAuthenticated } = useAuth();

    return (
        <Route
            {...rest}
            render={(props: any) =>
                isAuthenticated ? (
                    <Component exact={exact} {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
